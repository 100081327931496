<template>
  <div class="main">
    <div class="list-div">
      <van-list
          :loading="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
      >
        <div class="item" v-for="item in list" :key="item" @click="onClick(item.id)">
          <img class="cover" v-image="item.logo_img" />
          <div class="info">
            <p class="title">{{ item.chinese_name }}</p>
            <p>
              {{ item.area }}
            </p>
          </div>
        </div>
      </van-list>
    </div>

  </div>
</template>

<script>

import {
  ref,
  onMounted
} from 'vue';
import {Icon, Tag, Popup, Col, Row, Search, Tabs, Tab, List} from 'vant';
import {useRouter} from "vue-router";

export default {
  props: {
    list: Array,
    finished: Boolean,
    loading: Boolean
  },
  setup(props, {emit}) {
    const router = useRouter()
    const onLoad = () => {
      emit('onLoad', '快load吧');
    }
    const onClick = (id) => {
      // router.push('/product/product?id='+id)
      router.push({
        path: '/product/brand',
        query: {
          id: id
        }
      })
    }
    return {
      onLoad,
      onClick
    };
  },
  components: {
    [Icon.name]: Icon,
    [Tag.name]:Tag,
    [Popup.name]:Popup,
    [Col.name]:Col,
    [Row.name]:Row,
    [Search.name]:Search,
    [Tabs.name]:Tabs,
    [Tab.name]:Tab,
    [List.name]:List,
  }
};
</script>
<style scoped>
body {
  background-color: #f6f6f6;
}
.list-div {
  box-sizing: border-box;
}
.list-div .item {
  background-color: #fff;
  box-sizing: border-box;
  padding: 15px;
  margin-top: 10px;
  position: relative;
}
.item .cover {
  width: 100px;
  height: 100px;
  border-radius: 8px;
  border: 1px solid #f6f6f6;
}
.item .info {
  position: absolute;
  top: 0;
  left: 115px;
  padding: 15px;
}
.item .info .title {
  color: #000;
  font-weight: 700;
  margin-top: 0;
}
.item .info p {
  padding: 0;
}

</style>
