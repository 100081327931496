<template>
  <div class="main">
    <van-sticky>
      <van-search
          class="search"
          v-model="keyword"
          show-action
          shape="round"
          placeholder="请输入搜索关键词"
          @search="onSearch"
      >
        <template #action>
          <div @click="onSearch(keyword)">查询</div>
        </template>
      </van-search>
    </van-sticky>

    <van-tabs v-model:active="active" @click-tab="onClickTab" sticky swipeable offset-top="80px">
      <van-tab title="产品" name="product">
<!--        <SearchProduct :keyword="inputKeyword" style="height: 1000px; overflow-y: auto;" />-->
        <SearchProduct :keyword="inputKeyword"/>
      </van-tab>
      <van-tab title="成分" name="makeup">
        <SearchMakeup :keyword="inputKeyword" />
      </van-tab>
      <van-tab title="品牌" name="brand">
        <SearchBrand :keyword="inputKeyword" />
      </van-tab>
    </van-tabs>

  </div>
</template>

<script>

import {
  onActivated,
  ref,

} from 'vue';
import { useRoute } from 'vue-router';
import {Search, Tabs, Tab, List, Sticky} from 'vant';
import SearchProduct from "./components/SearchProduct";
import SearchMakeup from "./components/SearchMakeup";
import SearchBrand from "./components/SearchBrand";
import { unique } from "../../utils/Utils";

export default {
  components: {
    [Search.name]:Search,
    [Tabs.name]:Tabs,
    [Tab.name]:Tab,
    [List.name]:List,
    [Sticky.name]: Sticky,
    SearchProduct,
    SearchMakeup,
    SearchBrand
  },
  setup() {
    const route = useRoute();

    const keyword = ref('');
    const active = ref('product');
    const finished = ref(false);
    const loading = ref(false);
    const inputKeyword = ref('')

    const pushHistory = (keyword) => {
      const json = localStorage.getItem('searchHistory');
      let list = json ? JSON.parse(json) : [];
      list.unshift(keyword)
      list = unique(list)
      list = list.slice(0, 8)
      localStorage.setItem('searchHistory', JSON.stringify(list))
    }

    const onSearch = (keyword) => {
      inputKeyword.value = keyword
      pushHistory(keyword)
    }
    if (route.query.type) {
      active.value = route.query.type
    }
    if (route.query.keyword) {
      keyword.value = route.query.keyword
      onSearch(keyword.value)
    }
    onActivated( () => {

    })

    const onClickTab = (tab) => {
      console.log(tab)

    }

    return {
      inputKeyword,
      keyword,
      active,
      finished,
      loading,
      onSearch,
      onClickTab
    };
  }
};
</script>
<style scoped>
body {
  background-color: #f6f6f6;
}

.search {
  height: 80px;
}

</style>
