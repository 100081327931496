<template>
  <div class="main">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <BrandList v-if="!isEmpty" :list="list" :loading="loading" :finished="finished" @onLoad="onLoad" />
      <van-empty v-if="isEmpty" description="换个词搜索吧~" />
    </van-pull-refresh>

  </div>
</template>

<script>

import {
  ref,
  watch,
} from 'vue';
import { useRoute } from 'vue-router';
import {Icon, Tag, Popup, Col, Row, Search, Tabs, Tab, List, PullRefresh, Empty} from 'vant';
import Product from "../../../models/Product";
import BrandList from "./BrandList";

export default {
  props: {
    keyword: String
  },
  setup(props) {
    const finished = ref(false);
    const loading = ref(false);
    const list = ref([]);
    const page = ref(1);
    const refreshing = ref(false)
    const size = 20;
    const isEmpty = ref(false);

    const onLoad = () => {
      if (loading.value) {
        return ;
      }
      loading.value = true;
      let param = {
        'keyword': props.keyword,
        'page': page.value,
        'size': size
      }
      Product.searchBrand(param).then(res => {
        let dataList = res.data.list ? res.data.list : [];
        if (page.value === 1) {
          list.value = dataList;
          if (!dataList.length) {
            isEmpty.value = true;
          } else {
            isEmpty.value = false;
          }
        } else {
          dataList.forEach(value => {
            list.value.push(value)
          })
        }
        if (dataList.length < size) {
          finished.value = true;
        }
        page.value += 1;
        loading.value = false
        refreshing.value = false;
      })

    }
    watch(props, (newVal, oldVal) => {
      page.value = 1;
      finished.value = false;
      onLoad();
    })
    const onRefresh = () => {
      onLoad();
    }

    return {
      refreshing,
      finished,
      loading,
      list,
      isEmpty,
      onRefresh,
      onLoad
    };
  },
  components: {
    [Icon.name]: Icon,
    [Tag.name]:Tag,
    [Popup.name]:Popup,
    [Col.name]:Col,
    [Row.name]:Row,
    [Search.name]:Search,
    [Tabs.name]:Tabs,
    [Tab.name]:Tab,
    [List.name]:List,
    [PullRefresh.name]:PullRefresh,
    [Empty.name]: Empty,
    BrandList
  }
};
</script>
<style scoped>
body {
  background-color: #f6f6f6;
}
.search-div {
  padding: 15px 10px;
  background-color: #fff;
}
.list-div {
  box-sizing: border-box;
}
.list-div .item {
  background-color: #fff;
  box-sizing: border-box;
  padding: 15px;
  margin-top: 10px;
}

</style>