<template>
  <div class="list-div">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
          v-if="!isEmpty"
          :loading="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
      >
        <div class="item" v-for="makeup in list" :key="makeup" @click="onClick(makeup.id)">
          <p class="name">{{ makeup.display_name }}</p>
          <p class="name-en">{{ makeup.inci }}</p>
          <van-tag v-for="effect in makeup.target_name_list" :key="effect" plain type="warning" size="large">{{ effect }}</van-tag>
          <div class="score">
            <van-tag size="medium" v-if="makeup.risk>=0 && makeup.risk<=2" type="success">{{ makeup.risk }}</van-tag>
            <van-tag size="medium" v-if="makeup.risk>=3 && makeup.risk<=5" type="warning">{{ makeup.risk }}</van-tag>
            <van-tag size="medium" v-if="makeup.risk>=6 && makeup.risk<=9" type="danger">{{ makeup.risk }}</van-tag>
          </div>
        </div>
      </van-list>
      <van-empty v-if="isEmpty" description="换个词搜索吧~" />
    </van-pull-refresh>

  </div>
</template>

<script>

import {
  ref,
  watch,
} from 'vue';
import {Tag, List, PullRefresh, Empty} from 'vant';
import Product from "../../../models/Product";
import {useRouter} from "vue-router";

export default {
  props: {
    keyword: String
  },
  setup(props) {
    const router = useRouter()
    const finished = ref(false);
    const loading = ref(false);
    const list = ref([]);
    const page = ref(1);
    const refreshing = ref(false)
    const size = 20;
    const isEmpty = ref(false)
    const onLoad = () => {
      if (loading.value) {
        return ;
      }
      if (!props.keyword) {
        return ;
      }
      loading.value = true;
      let param = {
        'keyword': props.keyword,
        'page': page.value,
        'size': size
      }
      Product.searchMakeup(param).then(res => {
        let dataList = res.data.list ? res.data.list : [];
        if (page.value === 1) {
          list.value = dataList;
          if (!dataList.length) {
            isEmpty.value = true;
          } else {
            isEmpty.value = false;
          }
        } else {
          dataList.forEach(value => {
            list.value.push(value)
          })
        }
        if (dataList.length < size) {
          finished.value = true;
        }
        page.value += 1;
        loading.value = false
        refreshing.value = false;
      })

    }
    watch(props, (newVal, oldVal) => {
      page.value = 1;
      finished.value = false;
      onLoad();
    })
    const onRefresh = () => {
      onLoad();
    }
    const onClick = (id) => {
      router.push('/product/makeup?id='+id)
    }

    return {
      refreshing,
      finished,
      loading,
      list,
      isEmpty,
      onRefresh,
      onLoad,
      onClick
    };
  },
  components: {
    [Tag.name]: Tag,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [Empty.name]: Empty
  }
};
</script>
<style scoped>

.list-div {
  box-sizing: border-box;
}
.item {
  background-color: #fff;
  box-sizing: border-box;
  padding: 15px;
  margin-top: 10px;
  line-height: 30px;
  position: relative;
}
.item p {
  margin: 0;
  padding: 5px 0px;
}
.item .name {
  font-size: 18px;
  font-weight: 700;
}
.item .name-en {
  font-size: 14px;
}
.item .van-tag {
  margin-right: 15px;
}
.item .score {
  position: absolute;
  right: 0px;
  top: 19px;
}
</style>
